import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Visitor } from '../dtos/visitor';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class YardService {
  //apiUrl = `${environment.yardApiUrl}`; 
  appConfig: AppConfig;
  constructor(private httpClient: HttpClient, private configService: ConfigService) { 
    this.appConfig = this.configService.getConfig(); 
  }

  private getHeaders() {
    const username = 'admin@admin.com';
    const password = 'Woehrl4100';
    return new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`${this.appConfig.userName}:${this.appConfig.password}`)
    });
  }
  addVisitor(data: any): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.post(this.appConfig.apiUrl+'visitor/', data, { headers });
  }

  updateVistitor(data: Visitor): Observable<Visitor> {
    const headers = this.getHeaders();
    return this.httpClient.put<Visitor>(this.appConfig.apiUrl+'visitor/'+`${data.id}/`, data, { headers });
  }
}
